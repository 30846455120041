import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {MapsUtils} from 'src/smoothr-web-app-core/utils/maps-utils';
import {MapModalComponent} from 'src/app/components/map-modal/map-modal.component';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-home',
	templateUrl: './home-catering.page.cotidiano.html',
	styleUrls: ['./home-catering.page.cotidiano.scss']
})
export class HomeCateringPage extends RepositoryDirective implements OnInit {
	static url = 'catering';
	environment = environment;

	loading = false;
	sliderPages: {
		icon: string;
		title: string;
	}[] = [
		{
			icon: '/assets/cotidiano/people.svg',
			title: this.translate.instant('catering.slider.people')
		},
		{
			icon: '/assets/cotidiano/food.svg',
			title: this.translate.instant('catering.slider.food')
		},
		{
			icon: '/assets/cotidiano/time.svg',
			title: this.translate.instant('catering.slider.time')
		},
		{
			icon: '/assets/cotidiano/abholung.svg',
			title: this.translate.instant('catering.slider.take_away')
		}
	];
	slideOptions = {
		autoplay: {
			delay: 10000
		},
		speed: 600,
		loop: true
	};
	@ViewChild(IonSlides, {static: true}) slides: IonSlides;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		public translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomeCateringPage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
		this.slides.update();
	}

	async locateAndShowStores() {
		await MenuPage.navigate(this.router);
	}

	nextSlide() {
		this.slides.slideNext();
	}

	prevSlide() {
		this.slides.slidePrev();
	}

	async openMap() {
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		this.repository.address.emit(null);
		const result = await MapModalComponent.show(this.modalCtrl);
		if (result) {
			await sleep(200);
			console.log(result, this.order);

			// MenuPage.navigate(this.router);
			this.router.navigateByUrl(MenuPage.url, {
				replaceUrl: true
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}
}
