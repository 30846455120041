import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getBasePrice,
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() category: ArticleCategory;
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	numberToCurrency = numberToCurrency;
	getBasePrice = getBasePrice;
	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			if (this.article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = defaultsToArticleOption(
					this.article,
					[],
					this.article.defaults,
					OrderType.PREORDER,
					this.preorderType
				);
				articleGroup.quantity = 1;
				this.price = OrderUtils.totalPrice(
					articleGroup,
					OrderType.PREORDER,
					this.preorderType
				);
			} else {
				this.price = getPrice(
					this.article,
					OrderType.PREORDER,
					this.preorderType
				);
			}
		} else {
			this.price = 0;
		}
	}
	showImg() {
		if (this.category.mainCategory === 'dish') {
			return '../../../assets/cotidiano/dish.jpeg';
		} else {
			return '../../../assets/cotidiano/water.jpeg';
		}
	}
}
