import {Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {
	AlertController,
	IonSearchbar,
	ModalController,
	PopoverController
} from '@ionic/angular';
import {ModalInfoComponent} from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {AppComponent} from '../../app.component';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrderPage} from '../order/order.page';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {SelectVenueModalComponent} from '../../components/select-venue-modal/select-venue-modal.component';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	getAvailability,
	getSlots,
	numberD,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {OnlyNextDayModalComponent} from '../../components/only-next-day-modal/only-next-day-modal.component';
import {HomePopoverComponent} from 'src/app/components/home-popover/home-popover.component';
import {HomePage} from '../home/home.page';
import {CateringOrderType} from 'src/smoothr-web-app-core/enums/CateringOrderType';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss']
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, {static: false}) toOrderButton;
	@ViewChild('categoryList', {static: false}) categoryList;
	@ViewChild('articleContent', {static: false}) articleList;
	@ViewChild(IonSearchbar, {static: false}) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	isValid = false;
	orderUtils = OrderUtils;
	PreorderType = PreorderType;
	preorderType = PreorderType.TAKE_AWAY;
	orderType = OrderType;
	differenceToMvo: number;
	attempt = 1;
	venueOpen = true;
	venuePanic = false;
	ot = OrderType;
	pt = PreorderType;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AnalyticsService,
		private popoverCtrl: PopoverController
	) {
		super(repository);
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null
			) > 0
		);
	}

	get index(): number {
		return this.categories.findIndex(
			category => category._id === this.selectedCategory._id
		);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_cancel'),
					null,
					{
						duration: 2000
					}
				);
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_fail'),
					null,
					{
						duration: 5000
					}
				);
				break;
		}

		this.loading = true;
		await this.getVenue();
		this.reloadCategories();
		this.validate();
		this.loading = false;
	}

	async getVenue() {
		this.repository.venue.emit(
			await this.repository.getVenue(
				this.order && this.order.venue
					? this.order.venue
					: environment.customerGroup + '_master' //TODO: '_pasing'
			)
		);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			const inputElement = await this.searchBar.getInputElement();
			inputElement.value = '';
			this.searchTerm = '';
			this.reloadCategories();
			await sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 &&
						event.detail.currentY < array[index + 1]) ||
						index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;
		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(
				category => category._id === this.selectedCategory._id
			);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategory = this.categories[categoryIndex];
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	async openModal(item?: Article, category: ArticleCategory = null) {
		const isOpenAfterPopover = !!item;
		if (this.loading) {
			return;
		}
		if (!this.venueOpen || this.venuePanic) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_page.venue_panic_or_closed'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		if (
			!OrderUtils.isPreorder(this.order) &&
			!OrderUtils.isCatering(this.order)
		) {
			if (isOpenAfterPopover) {
				await OnlyNextDayModalComponent.show(this.modalCtrl);
			}
			const result = await SelectVenueModalComponent.show(
				this.modalCtrl,
				isOpenAfterPopover
			);
			if (result === undefined) {
				this.loading = false;
				return;
			}
			this.loading = true;
			const venue = await this.repository.getVenue(result.venue._id);
			this.repository.createOrder(
				result.venue,
				result.address,
				OrderType.PREORDER,
				result.preorderType
			);
			if (!isOpenAfterPopover) {
				this.loading = false;
				return;
			}
			const allArticles = [];
			venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));

			const articleInVenue = allArticles.find(
				art =>
					art._id === item._id || (item.masterId && art.masterId === item._id)
			);
			if (!articleInVenue) {
				this.loading = false;
				return;
			}
			item = articleInVenue;
		}
		console.log('after check');
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(
			...defaultsToArticleOption(
				item,
				[],
				item.defaults,
				OrderType.PREORDER,
				this.order.preorder.type
			)
		);
		if (articleGroup.article.groups.length > 0) {
			articleGroup.article.groups = articleGroup.article.groups.filter(
				it => it.articles.filter(art => !art.visible).length === 0
			);
		}

		// articleGroup.groups = articleGroup.groups.map(it => {
		// 		it.articles = it?.articles?.filter(it => !it?.visible);
		// 		return art.articles?.length === 0;
		// });
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen
				? 'item-modal large-modal'
				: 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
				category: category
			},
			mode: 'ios',
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(
				this.order,
				response.data.articleGroup,
				this.analytics
			);
			this.repository.order.emit(this.order);
		}
		this.loading = false;
	}

	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm =
			this.searchTerm && this.searchTerm !== ''
				? this.searchTerm.toLocaleLowerCase()
				: null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = getAvailability(
						article,
						OrderType.PREORDER,
						this.preorderType
					);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase()
						];
						return (
							available &&
							keys
								.map(key => key.indexOf(lowerSearchTerm))
								.find(result => result >= 0) !== undefined
						);
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
		return;
	}

	validate() {
		if (!this.venue || !this.order || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const mov =
			this.order.preorder.type === PreorderType.DELIVERY
				? numberD(this.venue.movDelivery)
				: 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(
					this.order && this.order.venue
						? this.order.venue
						: environment.customerGroup + '_master'
				)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.reloadPanicAndOpening();
					this.selectedCategory =
						this.venue && this.categories.length > 0
							? this.categories[0]
							: null;
					this.validate();
				});
			return;
		}
		this.reloadCategories();
		this.reloadPanicAndOpening();
		this.selectedCategory =
			this.venue && this.categories.length > 0 ? this.categories[0] : null;
		this.validate();
	}

	async reloadPanicAndOpening() {
		if (!this.venue) {
			this.venueOpen = false;
			this.venuePanic = false;
			return;
		}
		if (this.order && this.order.type === OrderType.CATERING) {
			this.venueOpen =
				(
					await getSlots(
						this.venue,
						OrderType.CATERING,
						null,
						null,
						0,
						false,
						1,
						CateringOrderType.TAKE_AWAY
					)
				).length > 0;
			this.venuePanic =
				!this.venue.isServiceActivated ||
				this.venue.panicEndAt.isAfter(moment());
			this.venuePanic = this.venue.panicEndAt.isAfter(moment());
			return;
		}
		this.venueOpen =
			(
				await getSlots(
					this.venue,
					OrderType.PREORDER,
					this.preorderType,
					null,
					null,
					null,
					3
				)
			).length > 0;
		this.venuePanic =
			!this.venue.isServiceActivated || this.venue.panicEndAt.isAfter(moment());
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	async showPopover() {
		const popover = await this.popoverCtrl.create({
			component: HomePopoverComponent,
			translucent: true,
			mode: 'ios',
			cssClass: 'home-popover'
		});
		await popover.present();
		const result = await popover.onDidDismiss();
		if (result.data) {
			await HomePage.navigate(this.router, this.repository);
			await this.openModal();
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo &&
				!this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(
			this.categoryList.nativeElement.children[index].offsetLeft - 25,
			0
		);
		await sleep(100);
	}

	private async scrollArticleListTo(index: number) {
		await this.articleList.scrollToPoint(
			0,
			this.articleList.el.children[index].offsetTop + 1,
			300
		);
	}
}
