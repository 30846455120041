import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const cotidianoCustomerGroup = 'cotidiano';
export const customerGroup = cotidianoCustomerGroup;

const supportEmail = {
	cotidiano: 'cotidiano@smoothr.de'
};
const firebaseConfig = {
	cotidiano: {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:eb98ed022b9f840ee0fe06',
		measurementId: 'G-NC4VQ3FW98'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAUJ0yCbuGUs9lDKehj-VGlD0oeU3XO__A',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	gaId: 'G-R8EYZ4J72X',
	facebookPixel: '440422684914917',
	...apiEnvironment,
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev'
};
