import {Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {MenuPage} from '../menu/menu.page';

@Component({
	selector: 'app-home',
	templateUrl: './home.page.cotidiano.html',
	styleUrls: ['./home.page.cotidiano.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	environment = environment;

	@ViewChild('backgroundVideo', {static: true}) video: HTMLVideoElement;

	loading = false;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores() {
		await MenuPage.navigate(this.router);
	}
}
