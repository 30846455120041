import {Component} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-home-popover',
	templateUrl: './home-popover.component.html',
	styleUrls: ['home-popover.component.scss']
})
export class HomePopoverComponent {
	environment = environment;

	constructor(private popoverCtrl: PopoverController) {}

	async change() {
		await this.popoverCtrl.dismiss(true);
	}

	async dismiss() {
		await this.popoverCtrl.dismiss(false);
	}
}
