import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {RegisterModalEnum} from './register-modal-enum';

@Component({
	selector: 'app-register-modal',
	templateUrl: './register-modal.component.html',
	styleUrls: ['register-modal.component.scss']
})
export class RegisterModalComponent {
	registerModalEnum = RegisterModalEnum;

	constructor(private modalController: ModalController) {}

	static async show(
		modalCtrl: ModalController
	): Promise<{status: RegisterModalEnum}> {
		const modal = await modalCtrl.create({
			component: RegisterModalComponent,
			cssClass: 'reg-modal auto-height',
			backdropDismiss: true
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	async dismiss(status: RegisterModalEnum) {
		await this.modalController.dismiss({status});
	}
}
