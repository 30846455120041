import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {MenuPage} from '../menu/menu.page';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {HomePage} from '../home/home.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Api} from '../../../smoothr-web-app-core/api/api';
import Address from '../../../smoothr-web-app-core/models/Address';
import {IonInput, ModalController} from '@ionic/angular';
import {DeliveryNotAvailableAction} from '../../enums/DeliveryNotAvailableAction';
import {TranslateService} from '@ngx-translate/core';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import {MapPage} from '../map/map.page';
import {
	sanitizeId,
	sleep,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import {DeliveryNotAvailableModalComponent} from '../../components/delivery-not-available-modal/delivery-not-available-modal.component';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

@Component({
	selector: 'app-venue-link',
	templateUrl: './venue-link.page.html',
	styleUrls: ['venue-link.page.scss']
})
export class VenueLinkPage implements OnInit {
	static READABLE_ID_PARAM = 'readableId';
	static urlWithParam = ':' + VenueLinkPage.READABLE_ID_PARAM;

	@ViewChild('mapContainer', {static: false})
	gmap: ElementRef;
	@ViewChild(IonInput, {static: true})
	inputField: IonInput;
	searchTerm: string;
	suggestedPlace: Address;
	map: Map;
	MapsUtils = MapsUtils;
	loading = true;
	venue: Venue;
	urlParam: string;
	address: Address;
	showAddressInput = false;
	venuesLoaded = false;
	pt = PreorderType;
	deliveryVenues: Venue[] = [];
	clusterer: MarkerClusterer = null;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{visibility: 'off'}]
			}
		]
	};
	showHint = false;
	predictions: AutocompletePrediction[] = [];
	selectedVenue: Venue = null;
	venueAcceptsOrders = venueAcceptsOrders;

	constructor(
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}

	ionViewDidEnter() {
		this.map = new Map(this.gmap.nativeElement, this.mapOptions);
		if (this.urlParam) {
			this.loadParamVenue();
			return;
		}
	}

	async initAutocomplete() {
		await MapsUtils.initAutocomplete(this.inputField, predictions => {
			this.predictions = predictions;
		});
		this.loading = false;
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.urlParam = params.get(VenueLinkPage.READABLE_ID_PARAM);
		});
		this.initAutocomplete();
	}

	async loadPlace(pred: AutocompletePrediction) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		const address = await MapsUtils.getPlace(pred);
		await this.fillInPlace(address);
		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadParamVenue() {
		this.loading = false;
		try {
			const venue = (await Api.getLazyVenue(this.urlParam)).data;
			if (!venue.isPublished) {
				await this.handleVenueNotFound();
				return;
			}
			this.venue = venue;
			this.venue.openingHours = TimeUtils.sanitizeHours(
				this.venue.openingHours
			);
			this.venue.deliveryHours = TimeUtils.sanitizeHours(
				this.venue.deliveryHours
			);
			this.setupMap(this.venue, [this.venue]);
		} catch (e) {
			if (e.response.status === 404) {
				await this.handleVenueNotFound();
			}
			console.error(e);
		}
	}

	async handleVenueNotFound() {
		this.snackbarCtrl.open(
			this.translate.instant('venue_page.venue_not_found'),
			null,
			{
				duration: 2000
			}
		);
		await sleep(2000);
		await HomePage.navigate(this.router);
	}

	async setupMap(selectedVenue: Venue, venues: Venue[]) {
		this.selectedVenue = selectedVenue;
		this.clusterer = await MapsUtils.addVenuesToMap(
			this.clusterer,
			selectedVenue,
			venues,
			this.map,
			venue => this.setupMap(venue, venues)
		);
	}

	async selectVenue(
		venue: Venue,
		preorderType: PreorderType,
		attempt: number = 0,
		prevError: any = null
	) {
		if (
			preorderType === PreorderType.DELIVERY &&
			!venue.distance &&
			!venue.isPostalDelivery
		) {
			this.showAddressInput = true;
			MapsUtils.getUserGeocode().then(result => {
				this.suggestedPlace = result;
				this.cdr.detectChanges();
			});
			return;
		}
		this.showAddressInput = false;

		if (attempt > 5) {
			this.loading = false;
			console.log(prevError);
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000
			});
			return;
		}
		this.loading = true;
		await this.repository.getVenue(venue._id);
		this.repository.createOrder(
			venue,
			this.address,
			OrderType.PREORDER,
			preorderType
		);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.showAddressInput = false;
		this.venuesLoaded = false;
		this.venue.distance = undefined;
		this.deliveryVenues = [];
		this.predictions = [];
		this.repository.address.emit(null);
	}

	async fillInPlace(address: any) {
		this.loading = true;
		try {
			this.address = address;
			await MapsUtils.fillInPlace(this.inputField, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!this.address) {
				await this.executeSearch();
				return;
			}
			this.predictions = [];
			await this.loadVenues(this.address);
		} catch (e) {
			this.venue.distance = undefined;
			this.venue.isPostalDelivery = false;
			this.snackbarCtrl.open(e, null, {
				duration: 2000
			});
			this.address = null;
		}
		this.loading = false;
	}

	async loadVenues(address: Address) {
		if (address.street === null || address.street === undefined) {
			this.address = null;
			this.loading = false;
			this.snackbarCtrl.open(
				this.translate.instant('home_page.street_error'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		this.repository.address.emit(address);
		try {
			const deliveryVenues = (
				await this.repository.getVenuesByAddress(address)
			).filter(ven => venueAcceptsOrders(ven, PreorderType.DELIVERY));
			// !ven.distance is true when is a postal delivery venue
			const venue = deliveryVenues.find(
				ven => ven.readableId === sanitizeId(this.urlParam)
			);
			if (venue) {
				this.venue = venue;
			} else {
				this.venue.distance = undefined;
				this.venue.isPostalDelivery = false;
				const choice = await DeliveryNotAvailableModalComponent.show(
					this.modalCtrl
				);
				switch (choice) {
					case DeliveryNotAvailableAction.DECLINE:
						if (deliveryVenues.length === 0) {
							await MapPage.navigate(this.router);
							await this.setupMap(null, deliveryVenues);
						} else {
							this.deliveryVenues = deliveryVenues;
							await this.setupMap(deliveryVenues[0], deliveryVenues);
							this.showAddressInput = false;
							this.showHint = true;
						}
						break;
					case DeliveryNotAvailableAction.TAKE_AWAY:
						await this.selectVenue(this.venue, PreorderType.TAKE_AWAY);
						break;
				}
			}
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
	}

	async executeSearch() {
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
	}
	changeSearchTerm(value: any) {
		this.searchTerm = value.detail.value;
	}
}
