import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Customer from '../../../smoothr-web-app-core/models/Customer';
import {AccountPage} from '../account/account.page';
import {Subscription} from 'rxjs';
import {HomePage} from '../home/home.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {MenuPage} from '../menu/menu.page';

@Component({
	selector: 'app-email-confirmation',
	templateUrl: './email-confirmation.page.html',
	styleUrls: ['email-confirmation.page.scss']
})
export class EmailConfirmationPage implements OnInit, OnDestroy {
	static url = 'confirm-email';

	customer: Customer;
	loading = false;
	private readonly subscription: Subscription;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef
	) {}

	static async navigate(router: Router, replaceUrl: boolean = false) {
		await router.navigateByUrl(EmailConfirmationPage.url, {
			replaceUrl
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([EmailConfirmationPage.url], {
			queryParams,
			replaceUrl: true
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (!params.token || !params.mode || params.mode !== 'verifyEmail') {
				this.findUser();
				return;
			}
			try {
				this.repository.customerAuth.emit(
					(await Api.verifyEmail(params.token)).data
				);
			} catch (e) {
				console.log('Error while verifying email');
			}
			await this.reloadUser();
		});
	}

	async reloadUser() {
		const customer = await this.repository.reloadCustomer();
		if (!customer || !customer.isAnonymous) {
			await HomePage.navigate(this.router);
		}
	}

	async findUser() {
		const response = await this.repository.reloadCustomer();
		console.log(response);
		if (response?.emailVerifiedAt && !response.isAnonymous) {
			this.customer = response;
		}
	}

	async refresh() {
		window.location.href = window.location.href;
	}

	async proceed() {
		await AccountPage.navigate(this.router);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	goBack() {
		MenuPage.navigate(this.router);
	}
}
