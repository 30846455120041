import {Component, EventEmitter, Input, Output} from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {WorkingHoursModalComponent} from '../working-hours-modal/working-hours-modal.component';
import {ModalController} from '@ionic/angular';
import moment, {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {
	getSlots,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {
	CateringOrderType,
	CateringOrderTypeArray
} from 'src/smoothr-web-app-core/enums/CateringOrderType';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';

@Component({
	selector: 'app-venue-suggestion-catering',
	templateUrl: './venue-suggestion-catering.component.html',
	styleUrls: ['venue-suggestion-catering.component.scss']
})
export class VenueSuggestionCateringComponent {
	environment = environment;
	@Input() orderType: OrderType;
	@Input() cateringOrderType: CateringOrderType;

	@Output() venuePick = new EventEmitter<{
		venue: Venue;
		type: CateringOrderType;
	}>();
	isVenueOpen = false;
	ct = CateringOrderType;
	@Input()
	overridePostalDelivery = false;
	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;
	//acceptCatering
	@Input()
	selected = false;
	ptArray = CateringOrderTypeArray;

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}

	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.acceptsDelivery = false;
		this.acceptsTakeaway = false;
		this.acceptsInside = false;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.firstInsideTime = null;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}
		this.isVenueOpen = this.checkVenueOpen(venue);
		getSlots(
			venue,
			OrderType.CATERING,
			null,
			null,
			0,
			false,
			7,
			CateringOrderType.TAKE_AWAY
		).then(slots => {
			this.acceptsTakeaway =
				slots.length > 0 &&
				venueAcceptsOrders(venue, null, false, CateringOrderType.TAKE_AWAY);
			if (this.acceptsTakeaway) {
				this.firstTakeAwayTime = this.timeToText(venue, moment(slots[0].time));
			}
		});
	}

	venueDeliveryByLiferando(venue: Venue): boolean {
		if (
			venue &&
			venue.deliveryByRadius &&
			venue?.externalDeliveryService?.link
		) {
			return true;
		} else {
			return false;
		}
	}

	selectVenue(venue: Venue, type: CateringOrderType) {
		if (!this.accepts(type)) {
			return;
		}
		this.venuePick.emit({venue, type});
	}

	accepts(type: CateringOrderType): boolean {
		switch (type) {
			case CateringOrderType.DELIVERY:
				return this.acceptsDelivery;
			case CateringOrderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			default:
				return false;
		}
	}

	openModal(venue: Venue) {
		WorkingHoursModalComponent.show(
			this.modalCtrl,
			venue,
			this.orderType,
			null,
			this.cateringOrderType
		);
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs(
					(moment().minutes() % venue.slot.interval) - venue.slot.interval
				) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) %
					venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday =
				now.dayOfYear() === relevantTime.dayOfYear() &&
				now.year() === relevantTime.year();
			return (
				(isToday
					? ''
					: this.translate.instant('venue_suggestion.tomorrow') + ' ') +
				relevantTime.format('HH:mm')
			);
		} else {
			return null;
		}
	}

	isVenueOpenFunc() {
		return this.isVenueOpen
			? this.translate.instant('venue_suggestion.open')
			: this.translate.instant('venue_suggestion.close');
	}

	checkVenueOpen(venue: Venue) {
		return (
			venue &&
			venue.isServiceActivated &&
			venue.cateringHoursTakeAway.length > 0 &&
			TimeUtils.doesHoursMatchNow(venue.cateringHoursTakeAway)
		);
	}
}
