import Article from './Article';
import Hours from './Hours';
import Assets from './Assets';
import Localization from './Localization';
import { DisplayMode } from '../enums/DisplayMode';
import { ArticleCategoryType } from '../enums/ArticleCategoryType';
import { ByType } from './ByType';

export default class ArticleCategory {
	// tslint:disable-next-line:variable-name
	_id: string;
	articles?: Article[];
	assets?: Assets;
	availableHours?: Hours[];
	deletedAt?: Date;
	displayMode?: DisplayMode;
	icon?: Assets;
	mainCategory: ArticleCategoryType;
	name: Localization;
	description: Localization;
	sortOrder?: number;
	venue?: string;
	visible: boolean;
	hidden: boolean;
	availability?: ByType<boolean>;
}
