import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {Router} from '@angular/router';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {ModalController} from '@ionic/angular';
import {HomePage} from '../../pages/home/home.page';
import {MyOrdersPage} from '../../pages/my-orders/my-orders.page';
import {AccountPage} from '../../pages/account/account.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import {TosPage} from '../../pages/tos/tos.page';
import {ImpressumPage} from '../../pages/impressum/impressum.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {FaqPage} from 'src/app/pages/faq/faq.page';
import {MenuPage} from 'src/app/pages/menu/menu.page';
import Order from 'src/smoothr-web-app-core/models/Order';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {HomeCateringPage} from 'src/app/pages/home-catering/home-catering.page';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['menu-modal.component.scss']
})
export class MenuModalComponent extends RepositoryDirective {
	environment = environment;
	languageEnum = Language;
	order: Order;
	ot = OrderType;

	constructor(
		public repository: RepositoryService,
		public router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService
	) {
		super(repository);
		console.log(this.router.url.includes('/catering'));
	}

	async signIn() {
		await this.modalController.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.modalController.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.modalController.dismiss();
		await AccountPage.navigate(this.router);
	}

	async myOrders() {
		await this.modalController.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_success'),
				null,
				{
					duration: 2000
				}
			);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_error'),
				null,
				{
					duration: 2000
				}
			);
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await HomePage.navigate(this.router);
	}

	async privacy() {
		await this.modalController.dismiss();
		await PrivacyPage.navigate(this.router);
	}

	async tos() {
		await this.modalController.dismiss();
		await TosPage.navigate(this.router);
	}

	async impressum() {
		await this.modalController.dismiss();
		ImpressumPage.navigate(this.router);
	}

	async allergens() {
		await this.modalController.dismiss();
		// TODO open PDF
	}

	async faq() {
		await this.modalController.dismiss();
		FaqPage.navigate(this.router);
	}

	dismiss() {
		this.modalController.dismiss();
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	async goToMenu() {
		await this.modalController.dismiss();
		if (this.repository?._order?.type === OrderType.CATERING) {
			sleep(200);
			MenuPage.navigate(this.router, this.repository);
			window.location.reload();
			return;
		}
		MenuPage.navigate(this.router, this.repository);
		return;
	}
	async goToCatering() {
		await this.modalController.dismiss();
		HomeCateringPage.navigate(this.router, this.repository);
		return;
	}
}
