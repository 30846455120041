import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IonInput, IonSlides, ModalController} from '@ionic/angular';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {TranslateService} from '@ngx-translate/core';
import {
	coverFlow,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MenuPage} from '../menu/menu.page';
import {Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import Address from '../../../smoothr-web-app-core/models/Address';
import {DeliveryNotAvailableModalComponent} from '../../components/delivery-not-available-modal/delivery-not-available-modal.component';
import {DeliveryNotAvailableAction} from '../../enums/DeliveryNotAvailableAction';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import {environment} from 'src/environments/environment';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss']
})
export class MapPage extends RepositoryDirective implements OnInit {
	static url = 'map';

	@ViewChild('mapContainer', {static: true})
	mapElement: ElementRef;
	@ViewChild(IonInput, {static: true})
	inputField: IonInput;
	@ViewChild(IonSlides, {static: true})
	slides: IonSlides;
	map: Map;
	environment = environment;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{visibility: 'off'}]
			}
		]
	};
	MapsUtils = MapsUtils;
	clusterer: MarkerClusterer = null;
	loading = false;
	predictions: AutocompletePrediction[] = [];
	showPredictions = false;
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2
		},
		on: coverFlow
	};

	selectedVenue: Venue;

	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address ? this.localVenues : this.allVenues;
	}

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}

	ionViewDidEnter() {}

	ngOnInit() {
		super.ngOnInit();
		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		this.initAutocomplete();
	}

	resetMap() {
		if (this.allVenues.length === 0) {
			Api.getAllVenues()
				.then(res => {
					this.allVenues = res.data
						.map(ven => {
							try {
								ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours);
								ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
							} catch (e) {
								console.error({
									message: 'Error while sanitizing hours ' + e,
									venue: ven.name + ' ' + ven.readableId,
									openingHours: ven.openingHours,
									deliveryHours: ven.deliveryHours
								});
							}
							return ven;
						})
						.filter(
							ven =>
								venueAcceptsOrders(ven, PreorderType.DELIVERY) ||
								venueAcceptsOrders(ven, PreorderType.TAKE_AWAY)
						);
					this.setupMap(null);
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => {
					this.setupMap(null);
				});
		} else {
			this.setupMap(null);
		}
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue;
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(
			this.clusterer,
			selectedVenue,
			this.relevantVenues,
			this.map,
			venue => this.setupMap(venue)
		);
		if (
			selectedVenue &&
			selectedVenue.location &&
			selectedVenue.location.coordinates
		) {
			const selectedIndex = this.relevantVenues.findIndex(
				ven => ven._id === selectedVenue._id
			);
			// wait until slides rendered
			await this.slides.length();
			await this.slides.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	hidePredictions() {
		setTimeout(() => {
			this.showPredictions = false;
		}, 100);
	}

	async executeSearch() {
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.predictions = [];
		this.selectedVenueForDelivery = null;
		this.selectedVenue = null;
		if (this.address !== null) {
			this.repository.address.emit(null);
		}
		this.showHint = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(address: any) {
		this.loading = true;
		try {
			await MapsUtils.fillInPlace(this.inputField, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				await this.executeSearch();
				return;
			}
			this.loading = false;
			this.repository.address.emit(address);
			await this.onSlideChange();
		} catch (e) {
			this.snackbarCtrl.open(e, null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = await this.repository.getVenuesByAddress(address);
			const addressString = MapsUtils.addressToString(address);
			(await this.inputField.getInputElement()).value = addressString;
			this.searchTerm = addressString;
			if (this.localVenues.length === 0 && !this.selectedVenueForDelivery) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: MapsUtils.addressToString(address)
					}),
					null,
					{
						duration: 2000
					}
				);
				this.loading = false;
				await this.resetSearch();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven =>
					venueAcceptsOrders(ven, PreorderType.DELIVERY)
				);
				const selectedDeliveryVenue = deliveryVenues.find(
					ven => ven._id === this.selectedVenueForDelivery._id
				);
				if (selectedDeliveryVenue) {
					await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				} else {
					const choice = await DeliveryNotAvailableModalComponent.show(
						this.modalCtrl
					);
					switch (choice) {
						case DeliveryNotAvailableAction.DECLINE:
							this.localVenues = deliveryVenues;
							if (deliveryVenues.length === 0) {
								this.repository.address.emit(null);
							} else {
								this.showHint = true;
							}
							await this.setupMap(null);
							break;
						case DeliveryNotAvailableAction.TAKE_AWAY:
							await this.selectVenue(
								this.selectedVenueForDelivery,
								PreorderType.TAKE_AWAY
							);
							break;
					}
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		console.log('onSlideChange()');
		let index = await this.slides.getActiveIndex();
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}

	async selectVenue(
		venue: Venue,
		preorderType: PreorderType,
		attempt: number = 0,
		prevError: any = null
	) {
		if (preorderType === PreorderType.DELIVERY && !this.address) {
			this.snackbarCtrl.open(
				this.translate.instant('map_page.enter_delivery_address'),
				null,
				{
					duration: 2000
				}
			);
			this.selectedVenueForDelivery = venue;
			return;
		}
		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			this.snackbarCtrl.open(
				this.translate.instant('map_page.venue_does_not_delivery_to_address'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000
			});
			return;
		}
		this.loading = true;
		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(
				venue,
				this.address,
				OrderType.PREORDER,
				preorderType
			);
			await MenuPage.navigate(this.router);
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}

	async initAutocomplete() {
		await MapsUtils.initAutocomplete(this.inputField, predictions => {
			this.predictions = predictions;
			this.cdr.detectChanges();
		});
		this.loading = false;
	}

	async loadPlace(prediction: AutocompletePrediction) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		const address = await MapsUtils.getPlace(prediction);
		await this.fillInPlace(address);
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		if (this.address) {
			this.loadVenues(this.address);
		} else {
			this.resetMap();
			this.resetSearch();
		}
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
	changeSearchTerm(value: any) {
		this.searchTerm = value.detail.value;
	}
}
