import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HomePage} from '../../pages/home/home.page';
import {MenuPage} from '../../pages/menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import Address from '../../../smoothr-web-app-core/models/Address';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {HomeCateringPage} from 'src/app/pages/home-catering/home-catering.page';

@Component({
	selector: 'app-toolbar-small',
	templateUrl: './toolbar-small.component.html',
	styleUrls: ['toolbar-small.component.scss']
})
export class ToolbarSmallComponent {
	environment = environment;
	@Input()
	address: Address;
	@Input()
	preorderType: PreorderType;
	@Input()
	venue: Venue;
	@Output() showPopover = new EventEmitter<void>();
	@Input()
	catering: boolean = false;

	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	home() {
		if (this.catering) {
			HomeCateringPage.navigate(this.router, this.repository);
			return;
		}
		if (!this.address) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}

	async presentHomePopover() {
		if (this.catering) {
			HomeCateringPage.navigate(this.router, this.repository);
			return;
		}
		this.showPopover.emit();
	}
}
