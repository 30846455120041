import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Address from 'src/smoothr-web-app-core/models/Address';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {
	coverFlow,
	sleep,
	venueAcceptsOrders
} from 'src/smoothr-web-app-core/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {DeliveryNotAvailableModalComponent} from '../delivery-not-available-modal/delivery-not-available-modal.component';
import {DeliveryNotAvailableAction} from 'src/app/enums/DeliveryNotAvailableAction';
import {GoogleMapsUtils} from './util/google-map';
import {CateringOrderType} from 'src/smoothr-web-app-core/enums/CateringOrderType';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

@Component({
	selector: 'app-map-modal',
	templateUrl: './map-modal.component.html',
	styleUrls: ['map-modal.component.scss']
})
export class MapModalComponent extends RepositoryDirective implements OnInit {
	@ViewChild('mapContainer', {static: true})
	mapElement: ElementRef;
	// @ViewChild(IonInput, { static: true })
	// inputField: IonInput;
	@ViewChild(IonSlides, {static: true})
	slides: IonSlides;
	map: Map;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{visibility: 'off'}]
			}
		]
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: Address[] = [];
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	pt = PreorderType;
	ct = CateringOrderType;
	orderType = OrderType;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2
		},
		on: coverFlow
	};
	showInputForAddress = false;
	showListOfVenues = true;
	selectedVenue: Venue;
	MapsUtils = GoogleMapsUtils;
	private _showPredictions = false;

	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address ? this.localVenues : this.allVenues;
	}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: MapModalComponent,
			componentProps: {},
			cssClass: 'map-modal',
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	ngOnInit() {
		super.ngOnInit();
		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		// tslint:disable-next-line:no-unused-expression
	}

	async resetMap() {
		if (this.allVenues.length === 0) {
			try {
				this.allVenues = (await Api.getAllVenues()).data
					.map(ven => {
						try {
							ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours);
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours
							});
						}
						return ven;
					})
					.filter(ven =>
						venueAcceptsOrders(ven, null, false, CateringOrderType.TAKE_AWAY)
					);
				console.log(this.allVenues);

				if (this.allVenues.length > 0) {
					this.selectedVenue = this.allVenues[0];
				} else {
					this.selectedVenue = null;
				}
			} catch (e) {}
		}
		await this.setupMap(this.selectedVenue);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue;
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = GoogleMapsUtils.addVenuesToMap(
			this.clusterer,
			selectedVenue,
			this.relevantVenues,
			this.map,
			venue => this.setupMap(venue)
		);
		if (
			selectedVenue &&
			selectedVenue.location &&
			selectedVenue.location.coordinates
		) {
			const selectedIndex = this.relevantVenues.findIndex(
				ven => ven._id === selectedVenue._id
			);
			// wait until slides rendered
			await this.slides.length();
			await this.slides.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = await this.repository.getVenuesByAddress(address);
			this.searchTerm = GoogleMapsUtils.addressToString(address);
			if (this.localVenues.length === 0 && !this.selectedVenueForDelivery) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm
					}),
					null,
					{
						duration: 2000
					}
				);
				this.loading = false;
				// await this.resetSearch();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven =>
					venueAcceptsOrders(ven, PreorderType.DELIVERY)
				);
				const selectedDeliveryVenue = deliveryVenues.find(
					ven => ven._id === this.selectedVenueForDelivery._id
				);
				if (selectedDeliveryVenue) {
					await this.selectVenue(
						selectedDeliveryVenue,
						OrderType.PREORDER,
						PreorderType.DELIVERY
					);
				} else {
					const choice = await DeliveryNotAvailableModalComponent.show(
						this.modalCtrl
					);
					switch (choice) {
						case DeliveryNotAvailableAction.DECLINE:
							this.localVenues = deliveryVenues;
							if (deliveryVenues.length === 0) {
								this.repository.address.emit(null);
							} else {
								this.showHint = true;
							}
							await this.setupMap(null);
							break;
						case DeliveryNotAvailableAction.TAKE_AWAY:
							await this.selectVenue(
								this.selectedVenueForDelivery,
								OrderType.PREORDER,
								PreorderType.TAKE_AWAY
							);
							break;
					}
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		if (this.localVenues.length > 0) {
			this.selectedVenue = this.localVenues[0];
		} else {
			this.selectedVenue = null;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides.getActiveIndex();
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}

	selectVenueToDelivery(venue: Venue) {
		this.selectedVenueForDelivery = venue;
	}

	selectVenueFunc(venue: Venue) {
		this.selectedVenue = venue;
		this.setupMap(venue);
	}

	async selectVenue(
		venue: Venue,
		orderType: OrderType,
		preorderType: PreorderType = null,
		cateringOrderType: CateringOrderType = null,
		attempt: number = 0,
		prevError: any = null
	) {
		if (orderType == OrderType.PREORDER) {
			if (
				preorderType === PreorderType.DELIVERY &&
				venue.distance > venue.deliveryRadius &&
				venue.deliveryByRadius &&
				!venue.isPostalDelivery
			) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.venue_does_not_delivery_to_address'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
			if (attempt > 5) {
				this.loading = false;
				this.snackbarCtrl.open(prevError, null, {
					duration: 2000
				});
				return;
			}
			if (this.loading) {
				return;
			}
			this.cdr.detectChanges();
			this.loading = true;

			try {
				await this.repository.getVenue(venue._id);
				this.repository.createOrder(
					venue,
					this.address,
					OrderType.PREORDER,
					preorderType
				);
				// await MenuPage.navigate(this.router);
				this.loading = false;
				this.cdr.detectChanges();
				this.modalCtrl.dismiss();
				return;
			} catch (e) {
				await this.selectVenue(
					venue,
					OrderType.PREORDER,
					preorderType,
					null,
					attempt + 1,
					e
				);
				this.loading = false;
				return;
			}
		}
		if (orderType === OrderType.CATERING) {
			if (attempt > 5) {
				this.loading = false;
				this.snackbarCtrl.open(prevError, null, {
					duration: 2000
				});
				return;
			}
			if (this.loading) {
				return;
			}
			this.cdr.detectChanges();
			this.loading = true;
			try {
				await this.repository.getVenue(venue._id);
				this.repository.createOrder(
					venue,
					this.address,
					OrderType.CATERING,
					null,
					null
				);
				this.addAdress(venue);
				this.loading = false;
				this.cdr.detectChanges();
				this.modalCtrl.dismiss('created');
				return;
			} catch (e) {
				await this.selectVenue(
					venue,
					OrderType.CATERING,
					null,
					cateringOrderType,
					attempt + 1,
					e
				);
				this.loading = false;
				return;
			}
		}
	}

	addAdress(venue: Venue) {
		const address = new Address();
		address.street = venue.street;
		address.city = venue.city.de;
		address.number = venue.number;
		address.postalCode = venue.postalCode;
		address.country = venue.country;
		address.lat = venue.location.coordinates[1];
		address.lng = venue.location.coordinates[0];
		this.repository.address.emit(address);
	}

	async loadPlace(pred: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		// await this.fillInPlace(pred);
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		// tslint:disable-next-line:no-unused-expression
		new Promise(async () => {
			if (this.address) {
				await this.loadVenues(this.address);
			} else {
				await this.resetMap();
				// this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}

	async changesAddress(address: Address) {
		await this.repository.address.emit(address);
		await this.onSlideChange();
	}
}
